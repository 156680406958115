import React from 'react'
// import { Link } from 'react-router-dom'
import "./footer.css"
import logo from '../static/hum-hai-logo.png'
// import { FiFacebook,FiInstagram,FiTwitter,FiLinkedin,FiYoutube,FiMail,FiPhone,FiMapPin} from 'react-icons/fi'

export const Footer = () => {
    return (
        // <section>Footer Example 4</section>
        <footer class="footer-distributed">
        
                    <div class="footer-left">
        
                        {/* <h3><span>{logo}</span></h3> */}
                        <img src={logo} alt="logo" />
{/*         
                        <p class="footer-links">
                            <a href="/" class="link-1">Home</a>
                            
                            <a href="/">Blog</a>
                        
                            <a href="/">Pricing</a>
                        
                            <a href="/">About</a>
                            
                            <a href="/">Faq</a>
                            
                            <a href="/">Contact</a>
                        </p> */}
        
                        <p class="footer-company-name">Amosta Solution PVT LTD © </p>
                    </div>
        
                    <div class="footer-center">
        
                        <div>
                            <i class="fa fa-map-marker"></i>
                           
                            <p> 301-B, Flatted Factory Complex, <span> Hartron Technology Park Plot No. 25A,</span><span> Electronic City, Sector 18,</span> Gurugram, Haryana 122015</p>
                        </div>
        
                        <div>
                            <i class="fa fa-phone"></i>
                            <p>+918882212649</p>
                        </div>
        
                        <div>
                            <i class="fa fa-envelope"></i>
                            <p><a href="mailto:support@company.com">info@sartracglobal.com</a></p>
                        </div>
        
                    </div>
        
                    {/* <div class="footer-right">
        
                        <p class="footer-company-about">
                            <span>About the company</span>
                            Lorem ipsum dolor sit amet, consectateur adispicing elit. Fusce euismod convallis velit, eu auctor lacus vehicula sit amet.
                        </p>
        
                        <div class="footer-icons">
        
                            <a href="/"><i class="fa fa-facebook"></i></a>
                            <a href="/"><i class="fa fa-twitter"></i></a>
                            <a href="/"><i class="fa fa-linkedin"></i></a>
                            <a href="/"><i class="fa fa-github"></i></a>
        
                        </div>
        
                    </div> */}
        
                </footer>
    )
}
