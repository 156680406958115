import { useState } from 'react'
// import { StarIcon } from '@heroicons/react/20/solid'
// import { RadioGroup } from '@headlessui/react'
import { NavBarOther } from '../navbar/NavBarOther'
import auditorium from '../static/auditorium.png'
import boardroom from '../static/board-room.png'
import banqquet from '../static/banquet-space.png'
import meetingroom from '../static/meeting-room.png'
// import conference from '../static/conference-room.png'

const product = {
  // name: 'Basic Tee 6-Pack',
  // price: '$192',
  // href: '#',
  // breadcrumbs: [
  //   { id: 1, name: 'Men', href: '#' },
  //   { id: 2, name: 'Clothing', href: '#' },
  // ],
  images: [
    {
      src: auditorium,
      alt: 'Two each of gray, white, and black shirts laying flat.',
    },
    {
      src: boardroom,
      alt: 'Model wearing plain black basic tee.',
    },
    {
      src: banqquet,
      alt: 'Model wearing plain gray basic tee.',
    },
    {
      src: meetingroom,
      alt: 'Model wearing plain white basic tee.',
    },
  ],
  coutaition: {
    OutdoorBanquetHall: [
      {
        key: "1",
        hrs: "1-3",
        charge: "35000",
        gst: "18%",
        gethering: "100-500"
      },
      {
        key: "2",
        hrs: "3-5",
        charge: "45000",
        gst: "18%",
        gethering: "100-500"
      },
      {
        key: "3",
        hrs: "5-6",
        charge: "55000",
        gst: "18%",
        gethering: "100-500"
      },
      {
        key: "4",
        hrs: "6-8",
        charge: "65000",
        gst: "18%",
        gethering: "100-500"
      },
      {
        key: "5",
        hrs: "8-10",
        charge: "75000",
        gst: "18%",
        gethering: "100-500"
      },
      {
        key: "6",
        hrs: "full day",
        charge: "100000",
        gst: "18%",
        gethering: "100-500"
      }
    ],
    OutdoorBanquetHallWithoutSetup:[
      {
        key: "1",
        hrs: "1-3",
        charge: "10000",
        gst: "18%",
        gethering: "100-500"
      },
      {
        key: "2",
        hrs: "1-3",
        charge: "15000",
        gst: "18%",
        gethering: "250"
      },
      {
        key: "3",
        hrs: "1-3",
        charge: "20000",
        gst: "18%",
        gethering: "300-350"
      },
      {
        key: "4",
        hrs: "4-6",
        charge: "25000",
        gst: "18%",
        gethering: "300-350"
      },
      {
        key: "5",
        hrs: "6-7",
        charge: "30000",
        gst: "18%",
        gethering: "300-350"
      }
    ],
   
    SmallDining:[
      {
        key: "1",
        hrs: "1",
        charge: "7500",
        gst: "18%",
        gethering: "100-150"
      },
      {
        key: "2",
        hrs: "2",
        charge: "10500",
        gst: "18%",
        gethering: "100-150"
      },
      {
        key: "3",
        hrs: "3",
        charge: "16800",
        gst: "18%",
        gethering: "100-150"
      },
      {
        key: "4",
        hrs: "4",
        charge: "20000",
        gst: "18%",
        gethering: "100-150"
      }
    ],
    OpenSpace:[
      {
        key: "1",
        hrs: "1",
        charge: "5000",
        gst: "18%",
        gethering: "As per that particular event"
      },
      {
        key: "2",
        hrs: "2",
        charge: "8000",
        gst: "18%",
        gethering: "As per that particular event"
      },
      {
        key: "3",
        hrs: "3",
        charge: "11000",
        gst: "18%",
        gethering: "As per that particular event"
      },
      {
        key: "4",
        hrs: "4",
        charge: "15000",
        gst: "18%",
        gethering: "As per that particular event"
      }
    ],
    MiniAuditorium:[
      {
        key: "1",
        hrs: "1-2",
        charge: "11200",
        gst: "18%",
        gethering: "100-150"
      },
      {
        key: "2",
        hrs: "2-3",
        charge: "16800",
        gst: "18%",
        gethering: "100-150"
      },
      {
        key: "3",
        hrs: "3-4",
        charge: "22400",
        gst: "18%",
        gethering: "100-150"
      },
      {
        key: "4",
        hrs: "4-5",
        charge: "28000",
        gst: "18%",
        gethering: "100-150"
      },
      {
        key: "5",
        hrs: "5-6",
        charge: "32000",
        gst: "18%",
        gethering: "100-150"
      },
      {
        key: "6",
        hrs: "6-7",
        charge: "35000",
        gst: "18%",
        gethering: "100-150"
      },
      {
        key: "7",
        hrs: "7-8",
        charge: "40000",
        gst: "18%",
        gethering: "100-150"
      },
      {
        key: "8",
        hrs: "8-9",
        charge: "45000",
        gst: "18%",
        gethering: "100-150"
      }
    ],
    ModernBalcony:[
      {
        key: "1",
        hrs: "1",
        charge: "15000",
        gst: "18%",
        gethering: "40-60"
      },
      {
        key: "2",
        hrs: "2",
        charge: "15000",
        gst: "18%",
        gethering: "40-60"
      },
      {
        key: "3",
        hrs: "3",
        charge: "20000",
        gst: "18%",
        gethering: "40-60"
      },
      {
        key: "4",
        hrs: "5",
        charge: "25000",
        gst: "18%",
        gethering: "40-60"
      },
      {
        key: "5",
        hrs: "7",
        charge: "30000",
        gst: "18%",
        gethering: "40-60"
      },
      {
        key: "6",
        hrs: "9",
        charge: "35000",
        gst: "18%",
        gethering: "40-60"
      },
      {
        key: "7",
        hrs: "12",
        charge: "40000",
        gst: "18%",
        gethering: "40-60"
      }
    ],
    UpperBuildingModernBalcony:[
      {
        key: "1",
        hrs: "1",
        charge: "15000",
        gst: "18%",
        gethering: "40-60"
      },
      {
        key: "2",
        hrs: "2",
        charge: "15000",
        gst: "18%",
        gethering: "40-60"
      },
      {
        key: "3",
        hrs: "3",
        charge: "20000",
        gst: "18%",
        gethering: "40-60"
      },
      {
        key: "4",
        hrs: "5",
        charge: "25000",
        gst: "18%",
        gethering: "40-60"
      },
      {
        key: "5",
        hrs: "7",
        charge: "30000",
        gst: "18%",
        gethering: "40-60"
      },
      {
        key: "6",
        hrs: "9",
        charge: "35000",
        gst: "18%",
        gethering: "40-60"
      },
      {
        key: "7",
        hrs: "12",
        charge: "40000",
        gst: "18%",
        gethering: "40-60"
      }
    ],
    IndoorParkingCharges:[
      {
        key: "1",
        hrs: "1",
        charge: "3500",
        gst: "18%",
        gethering: "40-100"
      },
      {
        key: "2",
        hrs: "2",
        charge: "7000",
        gst: "18%",
        gethering: "50-100"
      },
      {
        key: "3",
        hrs: "3",
        charge: "8000",
        gst: "18%",
        gethering: "50-100"
      },
      {
        key: "4",
        hrs: "4",
        charge: "10000",
        gst: "18%",
        gethering: "50-100"
      },
      {
        key: "5",
        hrs: "5",
        charge: "12000",
        gst: "18%",
        gethering: "50-100"
      },
      {
        key: "6",
        hrs: "6",
        charge: "14000",
        gst: "18%",
        gethering: "50-100"
      },
      {
        key: "7",
        hrs: "7",
        charge: "14000",
        gst: "18%",
        gethering: "50-100"
      },
      {
        key: "8",
        hrs: "8",
        charge: "16000",
        gst: "18%",
        gethering: "50-100"
      }
    ],
    ParkingChargesForIndividuals:[
      {
        key: "1",
        hrs: "1",
        charge: "50",
        gst: "18%",
        gethering: "1"
      },
      {
        key: "2",
        hrs: "2",
        charge: "70",
        gst: "18%",
        gethering: "1"
      },
      {
        key: "3",
        hrs: "3",
        charge: "100",
        gst: "18%",
        gethering: "1"
      },
      {
        key: "4",
        hrs: "4",
        charge: "120",
        gst: "18%",
        gethering: "1"
      },
      {
        key: "5",
        hrs: "5",
        charge: "140",
        gst: "18%",
        gethering: "1"
      }
    ],
   
  },

  colors: [
    { name: 'White', class: 'bg-white', selectedClass: 'ring-gray-400' },
    { name: 'Gray', class: 'bg-gray-200', selectedClass: 'ring-gray-400' },
    { name: 'Black', class: 'bg-gray-900', selectedClass: 'ring-gray-900' },
  ],

}


export const FacilityBookingDetails = () => {

  const [full_name, setFullName] = useState()
  const [email, setEmail] = useState()
  const [mobile, setMobile] = useState()
  const [organizer_name, setOrganizerName] = useState()
  const [event_date, setEventDate] = useState()
  const [event_duration, setEventDuration] = useState()
  const [event_detail, setEventDetail] = useState()
  const [lead_for, setLeadFor] = useState("")

  const getCookie = (name) => {
    var cookieValue = null;
    if (document.cookie && document.cookie !== "") {
      var cookies = document.cookie.split(";");
      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i].trim();
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) === name + "=") {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  };


  async function HandleEventManagement1(e) {
    e.preventDefault();
    console.log(full_name, email, "ghhgchgd")
    var csrftoken = getCookie("csrftoken");
    let result = await fetch('https://humhai.in/excelsior/excelsior_lead', {
      method: 'POST',
      body: JSON.stringify({ full_name: full_name, email: email, mobile: mobile, organizer_name: organizer_name, event_date: event_date, event_duration: event_duration, event_detail: event_detail, lead_for: lead_for }),
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Accept': 'application/json',
        'X-CSRFToken': csrftoken,
      }
    }).then(res => {
      console.log(res, "response")
      return res;
    }).catch(error => {
      console.log(error, "error")
    })

    console.log("after response");
    result = await result.json();
    console.log(result, "result");
    console.log(result.status, "true");
    if (result.status === true) {
      setEmail("")
      setFullName("")
      setOrganizerName("")
      setMobile("")
      setEventDuration("")
      setEventDate("")
      setLeadFor("")
      setEventDetail("")


    }
    console.log(result.msg);


  }
  return (
    <div className="bg-white">
      <NavBarOther />
      <div className="pt-6">
    

        {/* Image gallery */}
        <div className="mx-auto mt-6 max-w-2xl sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:gap-x-8 lg:px-8">
          <div className="aspect-h-4 aspect-w-3 hidden overflow-hidden rounded-lg lg:block">
            <img
              src={product.images[0].src}
              alt={product.images[0].alt}
              className="h-full w-full object-cover object-center"
            />
          </div>
          <div className="hidden lg:grid lg:grid-cols-1 lg:gap-y-8">
            <div className="aspect-h-2 aspect-w-3 overflow-hidden rounded-lg">
              <img
                src={product.images[1].src}
                alt={product.images[1].alt}
                className="h-full w-full object-cover object-center"
              />
            </div>
            <div className="aspect-h-2 aspect-w-3 overflow-hidden rounded-lg">
              <img
                src={product.images[2].src}
                alt={product.images[2].alt}
                className="h-full w-full object-cover object-center"
              />
            </div>
          </div>
          <div className="aspect-h-5 aspect-w-4 lg:aspect-h-4 lg:aspect-w-3 sm:overflow-hidden sm:rounded-lg">
            <img
              src={product.images[3].src}
              alt={product.images[3].alt}
              className="h-full w-full object-cover object-center"
            />
          </div>
        </div>

        {/* Product info */}
        <div 
        // className="mx-auto max-w-2xl px-4 pb-16 pt-10 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:grid-rows-[auto,auto,1fr] lg:gap-x-8 lg:px-8 lg:pb-24 lg:pt-16"
        >
          {/* <div className="lg:col-span-2 lg:border-r lg:border-gray-200 lg:pr-8">
            <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">{product.name}</h1>
          </div> */}

          {/* Options */}
          {/* <div className="mt-4 lg:row-span-3 lg:mt-0">
            <h2 className="sr-only">Product information</h2>
            <p className="text-3xl tracking-tight text-gray-900">{product.price}</p>

            Reviews

            <form className="w-full max-w-lg" onSubmit={HandleEventManagement1}>
              <div>
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                      Full Name
                    </label>
                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-first-name" type="text" placeholder="Full Name" onChange={e => { setFullName(e.target.value) }} value={full_name} required />
                    
                  </div>
                  <div className="w-full md:w-1/2 px-3">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                      Email
                    </label>
                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="email" placeholder="Email" onChange={e => { setEmail(e.target.value) }} value={email} required />
                  </div>
                </div>

              </div>
              <div>
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                      Mobile Number
                    </label>
                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-first-name" type="number" placeholder="Mobile Number" onChange={e => { setMobile(e.target.value) }} value={mobile} required />
          
                  </div>
                  <div className="w-full md:w-1/2 px-3">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                      Organizer Name
                    </label>
                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder=" Organizer Name" onChange={e => { setOrganizerName(e.target.value) }} value={organizer_name} required />
                  </div>
                </div>

              </div>
              <div>
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                      Event Date
                    </label>
                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-first-name" type="date" placeholder="Event Date" value={event_date} onChange={e => { setEventDate(e.target.value) }} required />
                   
                  </div>
                  <div className="w-full md:w-1/2 px-3">
                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                      Event duration(in min)
                    </label>
                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder=" Event duration(in min)" onChange={e => { setEventDuration(e.target.value) }} value={event_duration} required />
                  </div>
                </div>

              </div>


              <div>
                <div className="col-span-full">
                  <div className="mt-2">
                    <textarea placeholder=' Event Details' id="about" name="about" rows="3" className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      value={event_detail} onChange={(e) => setEventDetail(e.target.value)} required
                    ></textarea>
                    <input type='hidden' value={lead_for}
                    />
                  </div>

                </div>
              </div>



              <button
                type="submit"
                className="flex w-full mt-5 justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={() => setLeadFor("auditorium")}
              >
                Submit
              </button>

            </form>
          </div> */}

          <div className="py-10 lg:col-span-2 lg:col-start-1 lg:border-r lg:border-gray-200 lg:pb-16 lg:pr-8 lg:pt-6">
            
            <div className="mt-10">
              {/* <h3 className="text-sm font-medium text-gray-900">Highlights</h3> */}

              <div  className="mx-auto mt-6 max-w-2xl sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-3 lg:gap-x-8 lg:px-8">
                <div>
              <h2>Outdoor Banquet Hall Charges without setup for Individuals :for Non Auditorium Users.</h2>
               <table className="mt-4 mb-3 border-collapse border-spacing-2 border border-slate-400">
                 <thead>
                        <th className="border border-slate-300 ...">s.no</th>
                        <th className="border border-slate-300 ...">HRS</th>
                        <th className="border border-slate-300 ...">Space Rent</th>
                        <th className="border border-slate-300 ...">GST Extra</th>
                        <th className="border border-slate-300 ...">Gathering allowed under</th>
                      </thead>
                      <tbody>
                  {product.coutaition.OutdoorBanquetHall.map((highlight) => (
                   
                        <tr>
                          <th className="border border-slate-300 border-spacing: 1px 1px;">{highlight.key}</th>
                          <th className="border border-slate-300 border-spacing: 1px 1px;">{highlight.hrs}</th>
                          <th className="border border-slate-300 border-spacing: 1px 1px;">{highlight.charge}</th>
                          <th className="border border-slate-300 border-spacing: 1px 1px;">{highlight.gst}</th>
                          <th className="border border-slate-300 border-spacing: 1px 1px;">{highlight.gethering}</th>
                        </tr>
                  ))}
                  
                  
                   </tbody>

                </table>
                </div>
                <div>
                <h2>Outdoor Banquet Hall Charges without setup: for Auditorium event Users.</h2>
                  <table className="mt-4 mb-3 border-collapse border-spacing-2 border border-slate-400">
                    
                 <thead>
                        <th className="border border-slate-300 ...">s.no</th>
                        <th className="border border-slate-300 ...">HRS</th>
                        <th className="border border-slate-300 ...">Space Rent</th>
                        <th className="border border-slate-300 ...">GST Extra</th>
                        <th className="border border-slate-300 ...">Gathering allowed under</th>
                      </thead>
                      <tbody>
                 
                  {product.coutaition.OutdoorBanquetHallWithoutSetup.map((highlight) => (
                   
                        <tr>
                          <th className="border border-slate-300 border-spacing: 1px 1px;">{highlight.key}</th>
                          <th className="border border-slate-300 border-spacing: 1px 1px;">{highlight.hrs}</th>
                          <th className="border border-slate-300 border-spacing: 1px 1px;">{highlight.charge}</th>
                          <th className="border border-slate-300 border-spacing: 1px 1px;">{highlight.gst}</th>
                          <th className="border border-slate-300 border-spacing: 1px 1px;">{highlight.gethering}</th>
                        </tr>
                  ))}
                  
                   </tbody>

                </table>
                </div>
                <div>
                <h2>Small dining area near by volleyball court qutation charges</h2>
                  <table className="mt-4 mb-3 border-collapse border-spacing-2 border border-slate-400">
                    
                 <thead>
                        <th className="border border-slate-300 ...">s.no</th>
                        <th className="border border-slate-300 ...">HRS</th>
                        <th className="border border-slate-300 ...">Space Rent</th>
                        <th className="border border-slate-300 ...">GST Extra</th>
                        <th className="border border-slate-300 ...">Gathering allowed under</th>
                      </thead>
                      <tbody>
                 
                  {product.coutaition.SmallDining.map((highlight) => (
                   
                        <tr>
                          <th className="border border-slate-300 border-spacing: 1px 1px;">{highlight.key}</th>
                          <th className="border border-slate-300 border-spacing: 1px 1px;">{highlight.hrs}</th>
                          <th className="border border-slate-300 border-spacing: 1px 1px;">{highlight.charge}</th>
                          <th className="border border-slate-300 border-spacing: 1px 1px;">{highlight.gst}</th>
                          <th className="border border-slate-300 border-spacing: 1px 1px;">{highlight.gethering}</th>
                        </tr>
                  ))}
                  
                   </tbody>

                </table>
                </div>
                <div>
              <h2>Open Space-Per stall Charges</h2>
               <table className="mt-4 mb-3 border-collapse border-spacing-2 border border-slate-400">
                 <thead>
                        <th className="border border-slate-300 ...">s.no</th>
                        <th className="border border-slate-300 ...">HRS</th>
                        <th className="border border-slate-300 ...">Space Rent</th>
                        <th className="border border-slate-300 ...">GST Extra</th>
                        <th className="border border-slate-300 ...">Gathering allowed under</th>
                      </thead>
                      <tbody>
                  {product.coutaition.OpenSpace.map((highlight) => (
                   
                        <tr>
                          <th className="border border-slate-300 border-spacing: 1px 1px;">{highlight.key}</th>
                          <th className="border border-slate-300 border-spacing: 1px 1px;">{highlight.hrs}</th>
                          <th className="border border-slate-300 border-spacing: 1px 1px;">{highlight.charge}</th>
                          <th className="border border-slate-300 border-spacing: 1px 1px;">{highlight.gst}</th>
                          <th className="border border-slate-300 border-spacing: 1px 1px;">{highlight.gethering}</th>
                        </tr>
                  ))}
                  
                  
                   </tbody>

                </table>
                </div>
                <div>
                <h2>Mini Auditorium (Conference room)</h2>
                  <table className="mt-4 mb-3 border-collapse border-spacing-2 border border-slate-400">
                    
                 <thead>
                        <th className="border border-slate-300 ...">s.no</th>
                        <th className="border border-slate-300 ...">HRS</th>
                        <th className="border border-slate-300 ...">Space Rent</th>
                        <th className="border border-slate-300 ...">GST Extra</th>
                        <th className="border border-slate-300 ...">Gathering allowed under</th>
                      </thead>
                      <tbody>
                 
                  {product.coutaition.MiniAuditorium.map((highlight) => (
                   
                        <tr>
                          <th className="border border-slate-300 border-spacing: 1px 1px;">{highlight.key}</th>
                          <th className="border border-slate-300 border-spacing: 1px 1px;">{highlight.hrs}</th>
                          <th className="border border-slate-300 border-spacing: 1px 1px;">{highlight.charge}</th>
                          <th className="border border-slate-300 border-spacing: 1px 1px;">{highlight.gst}</th>
                          <th className="border border-slate-300 border-spacing: 1px 1px;">{highlight.gethering}</th>
                        </tr>
                  ))}
                  
                   </tbody>

                </table>
                </div>
                <div>
                <h2>Modern balcony for Dining or other uses</h2>
                  <table className="mt-4 mb-3 border-collapse border-spacing-2 border border-slate-400">
                    
                 <thead>
                        <th className="border border-slate-300 ...">s.no</th>
                        <th className="border border-slate-300 ...">HRS</th>
                        <th className="border border-slate-300 ...">Space Rent</th>
                        <th className="border border-slate-300 ...">GST Extra</th>
                        <th className="border border-slate-300 ...">Gathering allowed under</th>
                      </thead>
                      <tbody>
                 
                  {product.coutaition.ModernBalcony.map((highlight) => (
                   
                        <tr>
                          <th className="border border-slate-300 border-spacing: 1px 1px;">{highlight.key}</th>
                          <th className="border border-slate-300 border-spacing: 1px 1px;">{highlight.hrs}</th>
                          <th className="border border-slate-300 border-spacing: 1px 1px;">{highlight.charge}</th>
                          <th className="border border-slate-300 border-spacing: 1px 1px;">{highlight.gst}</th>
                          <th className="border border-slate-300 border-spacing: 1px 1px;">{highlight.gethering}</th>
                        </tr>
                  ))}
                  
                   </tbody>

                </table>
                </div>
                <div>
                <h2>Upper Building-Modern balcony for Dining or other</h2>
                  <table className="mt-4 mb-3 border-collapse border-spacing-2 border border-slate-400">
                    
                 <thead>
                        <th className="border border-slate-300 ...">s.no</th>
                        <th className="border border-slate-300 ...">HRS</th>
                        <th className="border border-slate-300 ...">Space Rent</th>
                        <th className="border border-slate-300 ...">GST Extra</th>
                        <th className="border border-slate-300 ...">Gathering allowed under</th>
                      </thead>
                      <tbody>
                 
                  {product.coutaition.UpperBuildingModernBalcony.map((highlight) => (
                   
                        <tr>
                          <th className="border border-slate-300 border-spacing: 1px 1px;">{highlight.key}</th>
                          <th className="border border-slate-300 border-spacing: 1px 1px;">{highlight.hrs}</th>
                          <th className="border border-slate-300 border-spacing: 1px 1px;">{highlight.charge}</th>
                          <th className="border border-slate-300 border-spacing: 1px 1px;">{highlight.gst}</th>
                          <th className="border border-slate-300 border-spacing: 1px 1px;">{highlight.gethering}</th>
                        </tr>
                  ))}
                  
                   </tbody>

                </table>
                </div>
                <div>
                <h2>Indoor Parking charges</h2>
                  <table className="mt-4 mb-3 border-collapse border-spacing-2 border border-slate-400">
                    
                 <thead>
                        <th className="border border-slate-300 ...">s.no</th>
                        <th className="border border-slate-300 ...">HRS</th>
                        <th className="border border-slate-300 ...">Space Rent</th>
                        <th className="border border-slate-300 ...">GST Extra</th>
                        <th className="border border-slate-300 ...">Gathering allowed under</th>
                      </thead>
                      <tbody>
                 
                  {product.coutaition.IndoorParkingCharges.map((highlight) => (
                   
                        <tr>
                          <th className="border border-slate-300 border-spacing: 1px 1px;">{highlight.key}</th>
                          <th className="border border-slate-300 border-spacing: 1px 1px;">{highlight.hrs}</th>
                          <th className="border border-slate-300 border-spacing: 1px 1px;">{highlight.charge}</th>
                          <th className="border border-slate-300 border-spacing: 1px 1px;">{highlight.gst}</th>
                          <th className="border border-slate-300 border-spacing: 1px 1px;">{highlight.gethering}</th>
                        </tr>
                  ))}
                  
                   </tbody>

                </table>
                </div>
                <div>
                <h2>Parking charges For Individuals</h2>
                  <table className="mt-4 mb-3 border-collapse border-spacing-2 border border-slate-400">
                    
                 <thead>
                        <th className="border border-slate-300 ...">s.no</th>
                        <th className="border border-slate-300 ...">HRS</th>
                        <th className="border border-slate-300 ...">Space Rent</th>
                        <th className="border border-slate-300 ...">GST Extra</th>
                        <th className="border border-slate-300 ...">Gathering allowed under</th>
                      </thead>
                      <tbody>
                 
                  {product.coutaition.ParkingChargesForIndividuals.map((highlight) => (
                   
                        <tr>
                          <th className="border border-slate-300 border-spacing: 1px 1px;">{highlight.key}</th>
                          <th className="border border-slate-300 border-spacing: 1px 1px;">{highlight.hrs}</th>
                          <th className="border border-slate-300 border-spacing: 1px 1px;">{highlight.charge}</th>
                          <th className="border border-slate-300 border-spacing: 1px 1px;">{highlight.gst}</th>
                          <th className="border border-slate-300 border-spacing: 1px 1px;">{highlight.gethering}</th>
                        </tr>
                  ))}
                  
                   </tbody>

                </table>
                </div>
              </div>
            </div>

            {/* <div className="mt-10">
              <h2 className="text-sm font-medium text-gray-900">Details</h2>

              <div className="mt-4 space-y-6">
                <p className="text-sm text-gray-600">{product.details}</p>
              </div>
            </div> */}
        
          </div>
        </div>
      </div>
    </div>
  )
}
