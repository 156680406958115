// import logo from './logo.svg';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
// import NavBar from "./component/navbar/NavBar"
import Login from "./component/Login/Login"
import Register from "./component/Login/Register"
import { Footer } from './component/home/Footer';
// import {Home} from "./component/home/Home"
import {Home} from "./component/home/Home"
import { EventManagement } from './component/eventmanagement/EventManagement';
import { FacilityBooking } from './component/facilitybooking/FacilityBooking';
import {ContactUs} from './component/home/ContactUs'
import { FacilityBookingDetails } from './component/facilitybooking/FacilityBookingDetails';
// import { Footer } from './component/home/Footer';
function App() {
  return (
    <div className="App">
   {/* <NavBar/> */}
   <BrowserRouter>
   <Routes>
    <Route path='/login' element={<Login/>}/>
    <Route path='/register' element={<Register/>}/>
    <Route path='/' element={<Home/>}/>
    <Route path='/eventmanagement' element={<EventManagement/>}/>
    <Route path='/facilitybooking' element={<FacilityBooking/>}/>
    <Route path='/contactus' element={<ContactUs/>}/>
    <Route path='/facilitybookingdetails' element={<FacilityBookingDetails/>}/>
   </Routes>
   </BrowserRouter>
   <Footer />
    </div>
  );
}

export default App;
