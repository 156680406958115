import React from 'react'
import { DialogTitle, DialogContent, DialogActions, Dialog, Button } from '@mui/material';

import headerImage from '../static/facility-header.png'
import auditorium from '../static/auditorium.png';
import outdoorBanquetSpace from '../static/banquet-space.png';
import boardRoom from '../static/board-room.png';
import conferenceRoom from '../static/conference-room.png';
import meetingRoom from '../static/meeting-room.png';
import { Link } from 'react-router-dom';
import { NavBarOther } from '../navbar/NavBarOther';
import { useState } from 'react';
import Slide from '@mui/material/Slide';
import DialogContentText from '@mui/material/DialogContentText';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Loading from 'react-loading';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

// import { useForm } from "react-hook-form";

export const FacilityBooking = (props) => {
    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [open3, setOpen3] = React.useState(false);
    const [open4, setOpen4] = React.useState(false);
    const [open5, setOpen5] = React.useState(false);

    const handleClickOpen = () => {
        setOpen5(true);
    };

    const handleClose5 = () => {
        setOpen5(false);
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleOpen1 = () => setOpen1(true);
    const handleClose1 = () => setOpen1(false);
    const handleOpen2 = () => setOpen2(true);
    const handleClose2 = () => setOpen2(false);
    const handleOpen3 = () => setOpen3(true);
    const handleClose3 = () => setOpen3(false);
    const handleOpen4 = () => setOpen4(true);
    const handleClose4 = () => setOpen4(false);




    const [full_name, setFullName] = useState()
    const [email, setEmail] = useState()
    const [mobile, setMobile] = useState()
    const [organizer_name, setOrganizerName] = useState()
    const [event_date, setEventDate] = useState()
    const [event_duration, setEventDuration] = useState()
    const [event_detail, setEventDetail] = useState()
    const [lead_for, setLeadFor] = useState("")
    const [isLoading, setIsLoading] = useState(false);


    // const { register, handleSubmit, formState: { errors } } = useForm();

    const getCookie = (name) => {
        var cookieValue = null;
        if (document.cookie && document.cookie !== "") {
            var cookies = document.cookie.split(";");
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i].trim();
                // Does this cookie string begin with the name we want?
                if (cookie.substring(0, name.length + 1) === name + "=") {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    };


    async function HandleEventManagement1(e) {
        e.preventDefault();
        setIsLoading(true)
        console.log(full_name, email, "ghhgchgd")
        var csrftoken = getCookie("csrftoken");
        let result = await fetch('https://humhai.in/excelsior/excelsior_lead', {
            method: 'POST',
            body: JSON.stringify({ full_name: full_name, email: email, mobile: mobile, organizer_name: organizer_name, event_date: event_date, event_duration: event_duration, event_detail: event_detail, lead_for: lead_for }),
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Accept': 'application/json',
                'X-CSRFToken': csrftoken,
            }
        }).then(res => {
            console.log(res, "response")
            return res;
        }).catch(error => {
            console.log(error, "error")
        })

        console.log("after response");
        result = await result.json();
        console.log(result, "result");
        console.log(result.status, "true");
        if (result.status === true) {
            setIsLoading(false)
            setEmail("")
            setFullName("")
            setOrganizerName("")
            setMobile("")
            setEventDuration("")
            setEventDate("")
            setLeadFor("")
            setEventDetail("")
            handleClose()
            handleClose1()
            handleClose2()
            handleClose3()
            handleClose4()
            handleClickOpen()

        }
        console.log(result.msg);


    }
    return (
        <div>
            <NavBarOther />
            <img alt="Placeholder" className="block h-auto w-full" src={headerImage} />



            <div style={{ width: '100%', color: "black", textAlign: 'center', justifyContent: 'center' }} >

                <div className='text-4xl mt-10 mb-5'>Welcome to Humhai Facility </div>


            </div>

            <div className="container my-8 mx-auto px-4 md:px-12">
                <div className="flex flex-wrap -mx-1 lg:-mx-4">

                    {/*  Column    */}
                    <div className="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">

                        {/*  Article    */}
                        <article className="overflow-hidden rounded-lg shadow-lg">


                            {/* <Link to='/facilitybookingdetails'> */}
                                 <img alt="Placeholder" className="block h-auto w-full" src={auditorium} />
                                 {/* </Link> */}


                            <header className="flex items-center justify-between leading-tight p-2 md:p-4">
                                <h1 className="text-2xl text-justify m-auto " > Auditorium </h1>
                            </header>

                            <footer className="flex items-center justify-between leading-none p-2 md:p-4">
                                <Link className="flex items-center no-underline  text-black w-96" >

                                    <button style={{ margin: "auto" }} onClick={handleOpen} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-4/5 m-auto">
                                        Book Now
                                    </button>
                                </Link>

                                <a className="no-underline text-grey-darker hover:text-red-dark" href="/">
                                    <span className="hidden">Like</span>
                                    <i className="fa fa-heart"></i>
                                </a>
                            </footer>

                        </article>
                        {/*  END Article    */}

                    </div>
                    {/*  END Column    */}

                    {/*  Column    */}
                    <div className="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">

                        {/*  Article    */}
                        <article className="overflow-hidden rounded-lg shadow-lg">


                            <img alt="Placeholder" className="block h-auto w-full" src={outdoorBanquetSpace} />


                            <header className="flex items-center justify-between leading-tight p-2 md:p-4">
                                <h1 className="text-2xl text-justify m-auto "> Outdoor Banquet Space</h1>

                            </header>

                            <footer className="flex items-center justify-between leading-none p-2 md:p-4">
                                <Link className="flex items-center no-underline  text-black w-96" >

                                    <button style={{ margin: "auto" }} onClick={handleOpen1} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-4/5 m-auto">
                                        Book Now
                                    </button>
                                </Link>
                                <a className="no-underline text-grey-darker hover:text-red-dark" href="/">
                                    <span className="hidden">Like</span>
                                    <i className="fa fa-heart"></i>
                                </a>
                            </footer>

                        </article>
                        {/*  END Article    */}

                    </div>
                    {/*  END Column    */}

                    {/*  Column    */}
                    <div className="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">

                        {/*  Article    */}
                        <article className="overflow-hidden rounded-lg shadow-lg">

                            <img alt="Placeholder" className="block h-auto w-full" src={boardRoom} />


                            <header className="flex items-center justify-between leading-tight p-2 md:p-4">
                                <h1 className="text-2xl text-justify m-auto"> Board Room </h1>

                            </header>

                            <footer className="flex items-center justify-between leading-none p-2 md:p-4">
                                <Link className="flex items-center no-underline  text-black w-96" >

                                    <button style={{ margin: "auto" }} onClick={handleOpen2} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-4/5 m-auto">
                                        Book Now
                                    </button>
                                </Link>
                                <a className="no-underline text-grey-darker hover:text-red-dark" href="/">
                                    <span className="hidden">Like</span>
                                    <i className="fa fa-heart"></i>
                                </a>
                            </footer>

                        </article>
                        {/*  END Article    */}

                    </div>
                    {/*  END Column    */}

                    {/*  Column    */}
                    <div className="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">

                        {/*  Article    */}
                        <article className="overflow-hidden rounded-lg shadow-lg">
                            <img alt="Placeholder" className="block h-auto w-full" src={conferenceRoom} />


                            <header className="flex items-center justify-between leading-tight p-2 md:p-4">
                                <h1 className="text-2xl text-justify m-auto ">Conference Room</h1>

                            </header>

                            <footer className="flex items-center justify-between leading-none p-2 md:p-4">
                                <Link className="flex items-center no-underline  text-black w-96" >

                                    <button style={{ margin: "auto" }} onClick={handleOpen3} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-4/5 m-auto">
                                        Book Now
                                    </button>
                                </Link>
                                <a className="no-underline text-grey-darker hover:text-red-dark" href="/">
                                    <span className="hidden">Like</span>
                                    <i className="fa fa-heart"></i>
                                </a>
                            </footer>

                        </article>
                        {/*  END Article    */}

                    </div>
                    {/*  END Column    */}

                    {/*  Column    */}
                    <div className="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3">

                        {/*  Article    */}
                        <article className="overflow-hidden rounded-lg shadow-lg">
                            <img alt="Placeholder" className="block h-auto w-full" src={meetingRoom} />

                            <header className="flex items-center justify-between leading-tight p-2 md:p-4">
                                <h1 className="text-2xl m-auto">Meeting Room </h1>

                            </header>

                            <footer className="flex items-center justify-between leading-none p-2 md:p-4">
                                <Link className="flex items-center no-underline  text-black w-96" >

                                    <button style={{ margin: "auto" }} onClick={handleOpen4} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2  rounded w-4/5 m-auto">
                                        Book Now
                                    </button>
                                </Link>

                            </footer>

                        </article>
                        {/*  END Article    */}

                    </div>
                    {/*  END Column    */}


                </div>
            </div>
            {/* auditorium modal */}
            <Dialog className="" open={open} onClose={handleClose}>
                <DialogTitle className="text-center">Auditorium</DialogTitle>
                {isLoading ? (
       <p style={{position: "absolute",top: "200px",left: "200px"}}> <Loading type="bars" color="#007bff" height={100} width={100} /></p>
      ) :""}

                <form onSubmit={HandleEventManagement1}>
                    <DialogContent>
                        <div>
                            <div className="flex flex-wrap -mx-3 mb-6">
                                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                        Full Name
                                    </label>
                                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-first-name" type="text" placeholder=" Full Name" onChange={e => { setFullName(e.target.value) }} value={full_name} required />
                                    {/* <p className="text-red-500 text-xs italic">Please fill out this field.</p> */}
                                </div>
                                <div className="w-full md:w-1/2 px-3">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                                        Email
                                    </label>
                                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="email" placeholder="Email" onChange={e => { setEmail(e.target.value) }} value={email} required />
                                </div>
                            </div>

                        </div>
                        <div>
                            <div className="flex flex-wrap -mx-3 mb-6">
                                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                        Mobile Number
                                    </label>
                                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-first-name" type="number" placeholder="Mobile Number" onChange={e => { setMobile(e.target.value) }} value={mobile} required />
                                    {/* <p className="text-red-500 text-xs italic">Please fill out this field.</p> */}
                                </div>
                                <div className="w-full md:w-1/2 px-3">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                                        Organizer Name
                                    </label>
                                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Organizer Name" onChange={e => { setOrganizerName(e.target.value) }} value={organizer_name} required />
                                </div>
                            </div>

                        </div>
                        <div>
                            <div className="flex flex-wrap -mx-3 mb-6">
                                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                        Event Date
                                    </label>
                                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-first-name" type="date" placeholder="Event Number" value={event_date} onChange={e => { setEventDate(e.target.value) }} required />
                                    {/* <p className="text-red-500 text-xs italic">Please fill out this field.</p> */}
                                </div>
                                <div className="w-full md:w-1/2 px-3">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                                        Event duration(in minutes)
                                    </label>
                                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Event Duration" onChange={e => { setEventDuration(e.target.value) }} value={event_duration} required />
                                </div>
                            </div>

                        </div>


                        <div>
                            <div className="col-span-full">
                                <div className="mt-2">
                                    <textarea placeholder=' Event Details' id="about" name="about" rows="3" className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        value={event_detail} onChange={(e) => setEventDetail(e.target.value)} required
                                    ></textarea>
                                    <input type='hidden' value={lead_for}
                                    />
                                </div>

                            </div>
                        </div>


                    </DialogContent>
                    <DialogActions>
                        <button
                            type="submit"
                            className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            onClick={() => setLeadFor("auditorium")}
                        >
                            Submit
                        </button>

                    </DialogActions>
                </form>
            </Dialog>
            {/* outdoor modal */}
            <Dialog className="" open={open1} onClose={handleClose1}>
                <DialogTitle className="text-center">Outdoor Banquet Space Booking</DialogTitle>
                {isLoading ? (
       <p style={{position: "absolute",top: "200px",left: "200px"}}> <Loading type="bars" color="#007bff" height={100} width={100} /></p>
      ) :""}
                <form onSubmit={HandleEventManagement1}>
                    <DialogContent>


                        <div>
                            <div className="flex flex-wrap -mx-3 mb-6">
                                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                        Full Name
                                    </label>
                                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-first-name" type="text" placeholder="Full Name" onChange={e => { setFullName(e.target.value) }} value={full_name} required />
                                    {/* <p className="text-red-500 text-xs italic">Please fill out this field.</p> */}
                                </div>
                                <div className="w-full md:w-1/2 px-3">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                                        Email
                                    </label>
                                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="email" placeholder="Email" onChange={e => { setEmail(e.target.value) }} value={email} required />
                                </div>
                            </div>

                        </div>
                        <div>
                            <div className="flex flex-wrap -mx-3 mb-6">
                                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                        Mobile Number
                                    </label>
                                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-first-name" type="number" placeholder="Mobile Number" onChange={e => { setMobile(e.target.value) }} value={mobile} required />
                                    {/* <p className="text-red-500 text-xs italic">Please fill out this field.</p> */}
                                </div>
                                <div className="w-full md:w-1/2 px-3">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                                        Organizer Name
                                    </label>
                                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Organizer Name" onChange={e => { setOrganizerName(e.target.value) }} value={organizer_name} required />
                                </div>
                            </div>

                        </div>
                        <div>
                            <div className="flex flex-wrap -mx-3 mb-6">
                                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                        Event Date
                                    </label>
                                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-first-name" type="date" placeholder="Event Date" value={event_date} onChange={e => { setEventDate(e.target.value) }} required />
                                    {/* <p className="text-red-500 text-xs italic">Please fill out this field.</p> */}
                                </div>
                                <div className="w-full md:w-1/2 px-3">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                                        Event duration(in minutes)
                                    </label>
                                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Event Duration" onChange={e => { setEventDuration(e.target.value) }} value={event_duration} required />
                                </div>
                            </div>

                        </div>


                        <div>
                            <div className="col-span-full">
                                <div className="mt-2">
                                    <textarea placeholder=' Event Details' id="about" name="about" rows="3" className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        value={event_detail} onChange={(e) => setEventDetail(e.target.value)} required
                                    ></textarea>
                                    <input type='hidden' value={lead_for}
                                    />
                                </div>

                            </div>
                        </div>


                    </DialogContent>
                    <DialogActions>
                        <button
                            type="submit"
                            className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            onClick={() => setLeadFor("Outdoor Banquet Space Booking")}
                        >
                            Submit
                        </button>

                    </DialogActions>
                </form>
            </Dialog>
            {/*  Board room modal */}
            <Dialog className="" open={open2} onClose={handleClose2}>
                <DialogTitle className="text-center">Board Room</DialogTitle>
                {isLoading ? (
       <p style={{position: "absolute",top: "200px",left: "200px"}}> <Loading type="bars" color="#007bff" height={100} width={100} /></p>
      ) :""}
                <form onSubmit={HandleEventManagement1}>
                    <DialogContent>
                        <div>
                            <div className="flex flex-wrap -mx-3 mb-6">
                                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                        Full Name
                                    </label>
                                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-first-name" type="text" placeholder="Full Name" onChange={e => { setFullName(e.target.value) }} value={full_name} required />
                                    {/* <p className="text-red-500 text-xs italic">Please fill out this field.</p> */}
                                </div>
                                <div className="w-full md:w-1/2 px-3">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                                        Email
                                    </label>
                                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="email" placeholder="Email" onChange={e => { setEmail(e.target.value) }} value={email} required />
                                </div>
                            </div>

                        </div>
                        <div>
                            <div className="flex flex-wrap -mx-3 mb-6">
                                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                        Mobile Number
                                    </label>
                                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-first-name" type="number" placeholder="Mobile Number" onChange={e => { setMobile(e.target.value) }} value={mobile} required />
                                    {/* <p className="text-red-500 text-xs italic">Please fill out this field.</p> */}
                                </div>
                                <div className="w-full md:w-1/2 px-3">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                                        Organizer Name
                                    </label>
                                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Organizer Name" onChange={e => { setOrganizerName(e.target.value) }} value={organizer_name} required />
                                </div>
                            </div>

                        </div>
                        <div>
                            <div className="flex flex-wrap -mx-3 mb-6">
                                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                        Event Date
                                    </label>
                                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-first-name" type="date" placeholder="Event Date" value={event_date} onChange={e => { setEventDate(e.target.value) }} required />
                                    {/* <p className="text-red-500 text-xs italic">Please fill out this field.</p> */}
                                </div>
                                <div className="w-full md:w-1/2 px-3">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                                        Event duration(in minutes)
                                    </label>
                                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Event Duration" onChange={e => { setEventDuration(e.target.value) }} value={event_duration} required />
                                </div>
                            </div>

                        </div>


                        <div>
                            <div className="col-span-full">
                                <div className="mt-2">
                                    <textarea placeholder=' Event Details' id="about" name="about" rows="3" className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        value={event_detail} onChange={(e) => setEventDetail(e.target.value)} required
                                    ></textarea>
                                    <input type='hidden' value={lead_for}
                                    />
                                </div>

                            </div>
                        </div>


                    </DialogContent>
                    <DialogActions>
                        <button
                            type="submit"
                            className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            onClick={() => setLeadFor("Board Room")}
                        >
                            Submit
                        </button>

                    </DialogActions>
                </form>
            </Dialog>
            {/* conference room modal */}
            <Dialog className="" open={open3} onClose={handleClose3}>
                <DialogTitle className="text-center">Conference Room</DialogTitle>
                {isLoading ? (
       <p style={{position: "absolute",top: "200px",left: "200px"}}> <Loading type="bars" color="#007bff" height={100} width={100} /></p>
      ) :""}
                <form onSubmit={HandleEventManagement1}>
                    <DialogContent>
                          <div>
                            <div className="flex flex-wrap -mx-3 mb-6">
                                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                        Full Name
                                    </label>
                                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-first-name" type="text" placeholder="Full Name" onChange={e => { setFullName(e.target.value) }} value={full_name} required />
                                    {/* <p className="text-red-500 text-xs italic">Please fill out this field.</p> */}
                                </div>
                                <div className="w-full md:w-1/2 px-3">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                                        Email
                                    </label>
                                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="email" placeholder="Email" onChange={e => { setEmail(e.target.value) }} value={email} required />
                                </div>
                            </div>

                        </div>
                        <div>
                            <div className="flex flex-wrap -mx-3 mb-6">
                                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                        Mobile Number
                                    </label>
                                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-first-name" type="number" placeholder="Mobile Number" onChange={e => { setMobile(e.target.value) }} value={mobile} required />
                                    {/* <p className="text-red-500 text-xs italic">Please fill out this field.</p> */}
                                </div>
                                <div className="w-full md:w-1/2 px-3">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                                        Organizer Name
                                    </label>
                                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Organizer Name" onChange={e => { setOrganizerName(e.target.value) }} value={organizer_name} required />
                                </div>
                            </div>

                        </div>
                        <div>
                            <div className="flex flex-wrap -mx-3 mb-6">
                                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                        Event Date
                                    </label>
                                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-first-name" type="date" placeholder="Event Date" value={event_date} onChange={e => { setEventDate(e.target.value) }} required />
                                    {/* <p className="text-red-500 text-xs italic">Please fill out this field.</p> */}
                                </div>
                                <div className="w-full md:w-1/2 px-3">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                                        Event duration(in minutes)
                                    </label>
                                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Event Duration" onChange={e => { setEventDuration(e.target.value) }} value={event_duration} required />
                                </div>
                            </div>

                        </div>


                        <div>
                            <div className="col-span-full">
                                <div className="mt-2">
                                    <textarea placeholder=' Event Details' id="about" name="about" rows="3" className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        value={event_detail} onChange={(e) => setEventDetail(e.target.value)} required
                                    ></textarea>
                                    <input type='hidden' value={lead_for}
                                    />
                                </div>

                            </div>
                        </div>


                    </DialogContent>
                    <DialogActions>
                        <button
                            type="submit"
                            className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            onClick={() => setLeadFor("Conference Room")}
                        >
                            Submit
                        </button>

                    </DialogActions>
                </form>
            </Dialog>
            {/* Meeting room modal */}
            <Dialog className="" open={open4} onClose={handleClose4}>
                <DialogTitle className="text-center">Meeting Room</DialogTitle>
                {isLoading ? (
       <p style={{position: "absolute",top: "200px",left: "200px"}}> <Loading type="bars" color="#007bff" height={100} width={100} /></p>
      ) :""}
                <form onSubmit={HandleEventManagement1}>
                    <DialogContent>
                        <div>
                            <div className="flex flex-wrap -mx-3 mb-6">
                                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                        Full Name
                                    </label>
                                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-first-name" type="text" placeholder="Full Name" onChange={e => { setFullName(e.target.value) }} value={full_name} required />
                                    {/* <p className="text-red-500 text-xs italic">Please fill out this field.</p> */}
                                </div>
                                <div className="w-full md:w-1/2 px-3">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                                        Email
                                    </label>
                                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="email" placeholder="Email" onChange={e => { setEmail(e.target.value) }} value={email} required />
                                </div>
                            </div>

                        </div>
                        <div>
                            <div className="flex flex-wrap -mx-3 mb-6">
                                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                        Mobile Number
                                    </label>
                                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-first-name" type="number" placeholder="Mobile Number" onChange={e => { setMobile(e.target.value) }} value={mobile} required />
                                    {/* <p className="text-red-500 text-xs italic">Please fill out this field.</p> */}
                                </div>
                                <div className="w-full md:w-1/2 px-3">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                                        Organizer Name
                                    </label>
                                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Organizer Name" onChange={e => { setOrganizerName(e.target.value) }} value={organizer_name} required />
                                </div>
                            </div>

                        </div>
                        <div>
                            <div className="flex flex-wrap -mx-3 mb-6">
                                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                        Event Date
                                    </label>
                                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-first-name" type="date" placeholder="Event Date" value={event_date} onChange={e => { setEventDate(e.target.value) }} required />
                                    {/* <p className="text-red-500 text-xs italic">Please fill out this field.</p> */}
                                </div>
                                <div className="w-full md:w-1/2 px-3">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                                        Event duration(in minutes)
                                    </label>
                                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Event Duration" onChange={e => { setEventDuration(e.target.value) }} value={event_duration} required />
                                </div>
                            </div>

                        </div>


                        <div>
                            <div className="col-span-full">
                                <div className="mt-2">
                                    <textarea placeholder=' Event Details' id="about" name="about" rows="3" className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        value={event_detail} onChange={(e) => setEventDetail(e.target.value)} required
                                    ></textarea>
                                    <input type='hidden' value={lead_for}
                                    />
                                </div>

                            </div>
                        </div>


                    </DialogContent>
                    <DialogActions>
                        <button
                            type="submit"
                            className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            onClick={() => setLeadFor("Meeting Room")}
                        >
                            Submit
                        </button>

                    </DialogActions>
                </form>
            </Dialog>

            {/* successful alert */}
            <Dialog
                open={open5}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose5}
                aria-describedby="alert-dialog-slide-description"
            >
                {/* <DialogTitle>{"Use Google's location service?"}</DialogTitle> */}
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <Stack sx={{ width: '100%' }} spacing={2}>

                            <Alert severity="success">This is a success alert — check it out!</Alert>
                        </Stack>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose5}>Ok</Button>

                </DialogActions>
            </Dialog>
        </div >
    )
}
