import React from 'react'
import './home.css';
import myVideo from "../static/bgvideo (4).mp4"
import NavBar from "../navbar/NavBar"
import { Link } from 'react-router-dom'
import { DialogTitle, DialogContent, DialogActions, Dialog,Button } from '@mui/material';
import { useState } from 'react';
import DialogContentText from '@mui/material/DialogContentText';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { Gallery } from './Gallery';
import Slide from '@mui/material/Slide';
import Loading from 'react-loading';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const Home = (props) => {
    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    const [full_name, setFullName] = useState()
    const [email, setEmail] = useState()
    const [mobile, setMobile] = useState()
    const [organizer_name, setOrganizerName] = useState()
    const [event_date, setEventDate] = useState()
    const [event_duration, setEventDuration] = useState()
    const [event_detail, setEventDetail] = useState()
    const lead_for = "event management"
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleOpen1 = () => setOpen1(true);
    const handleClose1 = () => setOpen1(false);
    const [isLoading, setIsLoading] = useState(false);

 


    // useEffect(() => {
    //   // Simulate a loading delay (remove this in your actual usage)
    //   const loadingTimeout = setTimeout(() => {
    //     setIsLoading(false);
    //   }, 2000);
  
    //   return () => clearTimeout(loadingTimeout);
    // }, []);
    const getCookie = (name) => {
        var cookieValue = null;
        if (document.cookie && document.cookie !== "") {
            var cookies = document.cookie.split(";");
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i].trim();
                // Does this cookie string begin with the name we want?
                if (cookie.substring(0, name.length + 1) === name + "=") {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    };

    async function HandleEventManagement(e) {
        e.preventDefault();
        setIsLoading(true);
        console.log(full_name, email, "ghhgchgd")
        var csrftoken = getCookie("csrftoken");
        let result = await fetch('https://humhai.in/excelsior/excelsior_lead', {
            method: 'POST',
            body: JSON.stringify({ full_name: full_name, email: email, mobile: mobile, organizer_name: organizer_name, event_date: event_date, event_duration: event_duration, event_detail:event_detail,lead_for:lead_for }),
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'Accept': 'application/json',
                'X-CSRFToken': csrftoken,
            }
        }).then(res => {
            console.log(res, "response")
            return res;
        }).catch(error => {
            console.log(error, "error")
        })

        console.log("after response");
        result = await result.json();
        console.log(result, "result");
        console.log(result.status, "true");
        // console.log(result.msg);
        if(result.status === true){
            setIsLoading(false);
            handleClose()
            handleOpen1()
            setFullName("")
            setEmail("")
            setMobile("")
            setOrganizerName("")
            setEventDate("")
            setEventDuration("")
            setEventDetail("")
          
        }


    }


    return (
        <div>
            <NavBar />
            <video autoPlay loop muted src={myVideo} style={{ height: "100vh", objectFit: "cover", width: "100%" }}></video>

   
            <div style={{ margin: 0, position: "absolute", top: "35%", left: "20%", width: '60%', color: "white", textAlign: 'center', justifyContent: 'center' }} >

                <div className='text-6xl'>Welcome to Humhai Facility </div>
                <div className='text-3xl mt-3.5'>Select an Option to continue</div>

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 20, }}>
                    <div>
                        <button onClick={handleOpen} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ">Event Management</button>
                    </div>
                    <div>
                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ms-8">
                            <Link to='/facilitybooking'>  Facility Booking</Link>  </button>
                    </div>
                </div>
            </div>
<Gallery/>
     



            <Dialog className="" open={open} onClose={handleClose}>
                <DialogTitle className="text-center">Event Management</DialogTitle>
                
                {isLoading ? (
       <p style={{position: "absolute",top: "200px",left: "200px"}}> <Loading type="bars" color="#007bff" height={100} width={100} /></p>
      ) :""}

                <form onSubmit={HandleEventManagement}>
                    <DialogContent>
                        <div>
                            <div className="flex flex-wrap -mx-3 mb-6">
                                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                        Full Name
                                    </label>
                                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-first-name" type="text" placeholder="Full Name" onChange={e => { setFullName(e.target.value) }} value={full_name} required />
                                    {/* <p className="text-red-500 text-xs italic">Please fill out this field.</p> */}
                                </div>
                                <div className="w-full md:w-1/2 px-3">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                                        Email
                                    </label>
                                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="email" placeholder="Email" onChange={e => { setEmail(e.target.value) }} value={email} required />
                                </div>
                            </div>

                        </div>
                       
                        <div>
                            <div className="flex flex-wrap -mx-3 mb-6">
                                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                        Mobile Number
                                    </label>
                                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-first-name" type="number" placeholder="Mobile Number" onChange={e => { setMobile(e.target.value) }} value={mobile} required />
                                    {/* <p className="text-red-500 text-xs italic">Please fill out this field.</p> */}
                                </div>
                                <div className="w-full md:w-1/2 px-3">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                                        Organizer Name
                                    </label>
                                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Organizer Name" onChange={e => { setOrganizerName(e.target.value) }} value={organizer_name} required />
                                </div>
                            </div>

                        </div>
                        <div>
                            <div className="flex flex-wrap -mx-3 mb-6">
                                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-first-name">
                                        Event Date
                                    </label>
                                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-first-name" type="date" placeholder="Event Date" value={event_date} onChange={e => { setEventDate(e.target.value) }} required />
                                    {/* <p className="text-red-500 text-xs italic">Please fill out this field.</p> */}
                                </div>
                                <div className="w-full md:w-1/2 px-3">
                                    <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="grid-last-name">
                                        Event duration(in minutes)
                                    </label>
                                    <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-last-name" type="text" placeholder="Event Duration" onChange={e => { setEventDuration(e.target.value) }} value={event_duration} required />
                                </div>
                            </div>

                        </div>


                        <div>
                            <div className="col-span-full">
                                <div className="mt-2">
                                    <textarea placeholder=' Event Details' id="about" name="about" rows="3" className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        value={event_detail} onChange={(e) => setEventDetail(e.target.value)} required
                                    ></textarea>
                                    <input type='hidden' value={lead_for}
                                    />
                                </div>

                            </div>
                        </div>


                    </DialogContent>
                    <DialogActions>
                        <button
                            type="submit"
                            className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                         
                        >
                            Submit
                        </button>

                    </DialogActions>
                </form>
            </Dialog>
            <Dialog
                open={open1}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose1}
                aria-describedby="alert-dialog-slide-description"
            >
                {/* <DialogTitle>{"Use Google's location service?"}</DialogTitle> */}
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <Stack sx={{ width: '100%' }} spacing={2}>

                            <Alert severity="success">This is a success alert — check it out!</Alert>
                        </Stack>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose1}>Ok</Button>

                </DialogActions>
            </Dialog>
        </div >
    )
}
