import React from 'react'
import { render } from "react-dom";
import CarouselSlider from "react-carousel-slider";
import auditorium from "../static/auditorium.png"
import mesho from "../static/meesho.png"


let data = [
    {
      des: "1",
      imgSrc: auditorium
    },
    {
      des: "2",
      imgSrc: auditorium
    },
    {
      des: "3",
      imgSrc:auditorium
    },
    {
      des: "4",
      imgSrc: auditorium
    },
    {
      des: "5",
      imgSrc:mesho
    }
  ];
  let sliderBoxStyle = {
    height: "250px",
    // width: "200px",
    background: "tranparent"
  };
  
  let itemsStyle = {
    // ,height: "100%", padding: "0px"
    // , padding: "15px"
    width:"200px",
    background: "transparent"
    // , borderRadius: "4px"
    // , margin: "0px 0px", padding: "0px"
  };
  
  let textBoxStyle = {
    // textAlign: "left"
    width:"50%"
    // , background: "transparent"
    // , fontSize: "36px"
    // , fontWeight: 300
  };
  
  let buttonSetting = {
    placeOn: "middle-inside",
    hoverEvent: true,
    style: {
      left: {
        margin: "0px 0px 0px 10px"
      },
      right: {
        margin: "0px 10px 0px 0px"
      }
    }
  };
  
  let manner = {
    autoSliding: {interval: "4s"}
    , duration: "0.1s"
  };
export const Gallery = () => {
  return (
    <CarouselSlider
    slideItems={data}
    manner={manner}
    buttonSetting={buttonSetting}
    sliderBoxStyle={sliderBoxStyle}
    itemsStyle={itemsStyle}
    textBoxStyle={textBoxStyle}
  />
  )
}
